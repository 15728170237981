import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    margin: '5px 0 15px 0',
  },
  text: {
    // fontWeight: 500,
    fontSize: '18px',
    lineHeight: '119%',
    color: '#2b64f6',
    textTransform: 'capitalize',
  },
  error: {
    // fontWeight: 500,
    fontSize: '14px',
    color: 'red',
  },
  iconStyle: {
    width: '19px',
    height: '19px',
    margin: '0 13px 0 0',
  },
}))

const GeolocationCheckbox = ({ geoText, onChange, checked, error }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={checked} onChange={onChange} color='primary' />
          }
          label={geoText}
          style={{ color: '#E4434F' }}
        />
        {error ? <span className={classes.error}>{error}</span> : null}
      </FormGroup>
    </div>
  )
}

export default GeolocationCheckbox
