import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import photoIcon from '../../../assets/photoIcon.png'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '15px 0 5px 0',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  text: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '119%',
    color: '#2b64f6',
    textTransform: 'capitalize',
  },
  iconStyle: {
    width: '21px',
    height: '15px',
    margin: '0 7px 0 0',
  },
  imgText: {
    fontSize: '18px',
  },
}))

const UploadImage = ({ images, setImages }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const handleUpload = async (e) => {
    let file = e.target.files[0]
    let value = await toBase64(file)
    setImages([{ fileName: file.name, fileType: file.type, value }, ...images])
  }

  const removeImage = (i) => {
    setImages(images.filter((item, index) => index !== i))
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <img src={photoIcon} alt='' className={classes.iconStyle} />
        <input
          accept='image/*'
          className={classes.input}
          style={{ display: 'none' }}
          id='raised-button-file'
          multiple
          type='file'
          onChange={handleUpload}
        />
        <label htmlFor='raised-button-file'>
          <Button
            variant='text'
            component='span'
            className={classes.text}
            style={{ color: '#E4434F' }}
          >
            {t('location:photos')}
          </Button>
        </label>
      </div>
      {images.map((item, i) => (
        <div className={classes.wrapper} key={item.fileName}>
          <Typography color='textPrimary' className={classes.imgText}>
            {item.fileName}
          </Typography>
          <IconButton
            onClick={() => removeImage(i)}
            style={{
              backgroundColor: '#EBEBEB',
              padding: '4px',
              margin: '0 0 0 5px',
            }}
          >
            <CloseIcon fontSize='small' />
          </IconButton>
        </div>
      ))}
    </div>
  )
}

export default UploadImage
