import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo.png";
import info from "../assets/info.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "row",
    height: 70,
    padding: 20,
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0 20px",
    },
  },
  logoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    width: 35,
    height: 35,
    marginRight: 8,
  },
  title: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  languagesWrapper: {
    display: "flex",
    flex: 2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  language: {
    color: "#3d3d3d",
    fontSize: "18px",
    padding: "0 4px",
  },
  activeLanguage: {
    textDecoration: "underline",
    fontWeight: "bold",
  },
  img: {
    width: "27px",
    height: "27px",
  },
  imgWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
  },
}));

const Header = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const history = useHistory();

  return (
    <div className={classes.wrapper}>
      <div onClick={() => history.push("/")} className={classes.logoWrapper}>
        <img alt="" src={logo} className={classes.logo} />
        <Typography className={classes.title}>QR Mine</Typography>
      </div>
      <div className={classes.languagesWrapper}>
        <Typography
          onClick={() => i18n.changeLanguage("de")}
          className={`${classes.language} ${
            i18n.language === "de" ? classes.activeLanguage : ""
          }`}
        >
          De
        </Typography>
        <Typography className={`${classes.language}`}>/</Typography>
        <Typography
          onClick={() => i18n.changeLanguage("en")}
          className={`${classes.language} ${
            i18n.language === "en" ? classes.activeLanguage : ""
          }`}
        >
          En
        </Typography>
      </div>
      <div
        className={classes.imgWrapper}
        onClick={() => history.push("/about")}
      >
        <img src={info} alt="" className={classes.img} />
      </div>
    </div>
  );
};

export default Header;
