import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo.png";
import contactIcon from "../../assets/contactIcon.png";
import locationIcon from "../../assets/locationIcon.png";
import shopIcon from "../../assets/shopIcon.png";
import Footer from "../Location/components/Footer";
import "./index.scss";
import { getQrCode } from "../../api";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "0 20px 20px",
    backgroundColor: "#fff",
    alignItems: "center",
    minHeight: "calc(100vh - 150px)",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 110px)",
    },
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      minHeight: "120px",
    },
  },
  logo: {
    height: "100%",
    maxHeight: "250px",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  title: {
    fontSize: "27px",
    fontWeight: "bold",
    lineHeight: "32px",
    margin: "25px 0",
  },
  bodyText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "131%",
    margin: "10px 0",
  },
  buttonsWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around",
    margin: "25px 0 0 0",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      flex: "initial",
    },
  },
  buttonCircle: {
    width: "70px",
    height: "70px",
    borderRadius: "35px",
    backgroundColor: "#d8d8d8",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonText: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "119%",
    textAlign: "center",
    margin: "10px 0 0 0",
  },
  iconStyle: {
    width: "100%",
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const id = props?.match?.params?.id;

  useEffect(() => {
    getQrCode(id)
      .then((data) => {
        if (data.qrCode && data.qrCode.edition) {
          if (!data.qrCode.userId) {
            history.replace(
              `/edition/${data.qrCode.edition}/${data.qrCode._id}`
            );
          }
        }
      })
      .catch(() => {
        history.replace(`/`);
      });
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}>
        <img
          src={logo}
          style={{ animation: `spin 2s ease-in-out` }}
          className={classes.logo}
          alt=""
        />
      </div>
      <Typography color="textPrimary" className={classes.title} align="center">
        {t("webMain:thanks")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("webMain:help")}
      </Typography>
      <div className={classes.buttonsWrapper}>
        <div
          className={classes.buttonWrapper}
          onClick={() => history.push("/contact", { id })}
        >
          <div className={classes.buttonCircle}>
            <img src={contactIcon} alt="" className={classes.iconStyle} />
          </div>
          <Typography className={classes.buttonText} color="textPrimary">
            {t("webMain:contact")}
          </Typography>
        </div>
        <div
          className={classes.buttonWrapper}
          onClick={() => history.push("/location", { id })}
        >
          <div className={classes.buttonCircle}>
            <img src={locationIcon} alt="" className={classes.iconStyle} />
          </div>
          <Typography className={classes.buttonText} color="textPrimary">
            {t("webMain:location")}
          </Typography>
        </div>
        <div
          className={classes.buttonWrapper}
          onClick={() => history.push("/shop", { id })}
        >
          <div className={classes.buttonCircle}>
            <img src={shopIcon} alt="" className={classes.iconStyle} />
          </div>
          <Typography className={classes.buttonText} color="textPrimary">
            {t("webMain:shop")}
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
