import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: "flex",
		flexDirection: "row",
		flex: 1,
		backgroundColor: "#fff",
		width: "100%",
	},
	title: {
		fontSize: "27px",
		fontWeight: "bold",
		lineHeight: "36px",
		// margin: '25px 0'
	},
	bodyText: {
		fontWeight: 500,
		fontSize: "18px",
		lineHeight: "131%",
		margin: "10px 0",
		maxWidth: "100%",
		wordBreak: "break-word",
	},
	number: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "36px",
		height: "36px",
		borderRadius: "50%",
		fontWeight: "800",
		fontSize: "27px",
		color: "#fff",
		backgroundColor: "#e24450",
	},
	textWrapper: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		marginLeft: "20px",
	},
}));

const AboutItem = ({ number, title, text }) => {
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<div className={classes.number}>{number}</div>
			<div className={classes.textWrapper}>
				<Typography
					color='textPrimary'
					className={classes.title}
				>
					{title}
				</Typography>
				<Typography
					color='textPrimary'
					className={classes.bodyText}
				>
					{text}
				</Typography>
			</div>
		</div>
	);
};

export default AboutItem;
