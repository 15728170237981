import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Footer from "../Location/components/Footer";
import AboutItem from "./components/AboutItem";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "20px",
    backgroundColor: "#fff",
    alignItems: "center",
    minHeight: "calc(100vh - 150px)",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 110px)",
    },
  },
  title: {
    fontSize: "27px",
    fontWeight: "bold",
    lineHeight: "32px",
    // margin: '25px 0'
  },
  bodyText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "131%",
    margin: "20px 0",
    maxWidth: "100%",
    wordBreak: "break-word",
  },
}));

const Impressum = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Typography color="textPrimary" className={classes.title} align="center">
        {t("about:title1")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("about:text1")}
      </Typography>
      <AboutItem number={1} title={t("about:title2")} text={t("about:text2")} />
      <AboutItem number={2} title={t("about:title3")} text={t("about:text3")} />
      <AboutItem number={3} title={t("about:title4")} text={t("about:text4")} />
      <AboutItem number={4} title={t("about:title5")} text={t("about:text5")} />
      <Footer />
    </div>
  );
};

export default Impressum;
