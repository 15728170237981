const API_ENDPOINT =
  // process.env.NODE_ENV === "development"
  //   ? "http://localhost:3000/api"
  //   :
    "https://admin.qr-mine.app/api";

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    console.log(response.url, response.status, data);
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        localStorage.removeItem("auth-token");
      }
      const error = data || { message: response.statusText };
      return Promise.reject(error);
    }

    return data;
  });
};

export const sendMessage = (message, userId) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  };

  return fetch(`${API_ENDPOINT}/messages/${userId}`, options)
    .then(handleResponse)
    .then((res) => res);
};

export const getLanguages = () => {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${API_ENDPOINT}/i18n`, options)
    .then(handleResponse)
    .then((res) => res.data);
};

// export const getLanguages = (options, url, payload, callback) => {
//     // const options = {
//     //     method: 'GET',
//     //     headers: { 'Content-Type': 'application/json' },
//     // }

//     return fetch(`${API_ENDPOINT}/i18n`, options)
//         .then(handleResponse)
//         .then(res => callback(res.data))
//         .catch(() => callback())
// }

export const getQrCode = (qrCodeId) => {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${API_ENDPOINT}/qr-code/${qrCodeId}`, options)
    .then(handleResponse)
    .then((res) => res.data);
};

export const getQrCodeEdition = (qrCodeEditionId) => {
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${API_ENDPOINT}/qr-code/edition/${qrCodeEditionId}`, options)
    .then(handleResponse)
    .then((res) => res.data);
};
