import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Routes from "./Routes";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getLanguages } from "./api";

const muiTheme = createTheme({
	palette: {
		primary: {
			main: "#e24450",
		},
		secondary: {
			main: "#ebebeb",
		},
		text: {
			primary: "#3d3d3d",
		},
	},
});

const App = () => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		getLanguages().then((resources) => {
			i18n
				.use(initReactI18next) // passes i18n down to react-i18next
				.init({
					resources,
					react: {
						useSuspense: false,
					},
					lng: "de",
					fallbackLng: "de",
					supportedLngs: ["de", "en"],
					interpolation: {
						escapeValue: false,
					},
				})
				.then(() => setLoading(false));
		});
	}, []);

	if (loading) return null;

	return (
		<ThemeProvider theme={muiTheme}>
			<Routes />
		</ThemeProvider>
	);
};

export default App;
