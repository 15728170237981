import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import Footer from "../Location/components/Footer";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "20px",
    backgroundColor: "#fff",
    alignItems: "flex-start",
    minHeight: "calc(100vh - 150px)",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 110px)",
    },
  },
  title: {
    fontSize: "27px",
    fontWeight: "bold",
    lineHeight: "32px",
    alignSelf: "center",
    marginBottom: "20px",
    // margin: '25px 0'
  },
  subtitle: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "20px",
    // margin: '25px 0'
  },
  bodyText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "131%",
    margin: "20px 0",
    maxWidth: "100%",
    wordBreak: "break-word",
    textAlign: "left",
    whiteSpace: "pre-wrap",
  },
  link: {
    color: "#878787",
    fontSize: "18px",
    margin: "0 5px",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
  },
}));

const Impressum = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapper}>
      <Typography color="textPrimary" className={classes.title} align="center">
        {t("gdpr:title")}
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.subtitle}
        align="center"
      >
        {t("gdpr:title1")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("gdpr:text1")}
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.subtitle}
        align="center"
      >
        {t("gdpr:title2")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("gdpr:text2")}
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.subtitle}
        align="center"
      >
        {t("gdpr:title3")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("gdpr:text3")}
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.subtitle}
        align="center"
      >
        {t("gdpr:title4")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("gdpr:text4")}
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.subtitle}
        align="center"
      >
        {t("gdpr:title5")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("gdpr:text5")}
        <a
          href={`${t("gdpr:link5")}`}
          className={classes.link}
          target="_blank"
          rel="noreferrer"
        >
          {t("gdpr:link5")}
        </a>
        {t("gdpr:text5_1")}
      </Typography>
      <Typography
        color="textPrimary"
        className={classes.subtitle}
        align="center"
      >
        {t("gdpr:title6")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("gdpr:text6")}
      </Typography>
      <Footer />
    </div>
  );
};

export default Impressum;
