import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./components/Header";

import Home from "./screens/Home";
import Contact from "./screens/Contact";
import Location from "./screens/Location";
import Shop from "./screens/Shop";
import Thanks from "./screens/Thanks";
import Edition from "./screens/Edition";
import Datenschutz from "./screens/Datenschutz";
import Impressum from "./screens/Impressum";
import About from "./screens/About";
import Terms from "./screens/Terms";

const Routes = () => (
  <Router>
    <Header />
    <Switch>
      <Route path="/" exact render={() => <Redirect to="/about" />} />
      <Route path="/contact" component={Contact} />
      <Route path="/location" component={Location} />
      <Route path="/shop" component={Shop} />
      <Route path="/thanks" component={Thanks} />
      <Route path="/edition/:qrCodeEditionId/:qrCodeId" component={Edition} />
      <Route path="/datenschutz" component={Datenschutz} />
      <Route path="/impressum" component={Impressum} />
      <Route path="/about" component={About} />
      <Route path="/terms" component={Terms} />
      <Route path="/:id" exact component={Home} />
    </Switch>
  </Router>
);

export default Routes;
