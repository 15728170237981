import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo.png";
import { getQrCodeEdition } from "../../api";
import Footer from "../Location/components/Footer";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: "0 20px 20px",
    backgroundColor: "#fff",
    alignItems: "center",
    minHeight: "calc(100vh - 150px)",
    [theme.breakpoints.down("xs")]: {
      minHeight: "calc(100vh - 110px)",
    },
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      minHeight: "120px",
    },
  },
  logo: {
    height: "100%",
    maxHeight: "250px",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
    },
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: "30px",
    margin: "25px 0",
  },
  bodyText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "131%",
    margin: "10px 0",
  },
  downloadLinks: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  learnMore: {
    color: "#878787",
    fontSize: "10px",
    fontWeight: 500,
    flex: 1,
  },
  learnMoreLink: {
    color: "#878787",
    margin: "0 5px",
    fontSize: "10px",
    fontWeight: 500,
  },
}));

const Edition = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const qrCodeEditionId = props?.match?.params?.qrCodeEditionId;
  const [edition, setEdition] = useState(null);

  useEffect(() => {
    getQrCodeEdition(qrCodeEditionId)
      .then((data) => {
        if (data.qrCodeEdition) {
          setEdition(data.qrCodeEdition);
        } else {
          history.replace(`/`);
        }
      })
      .catch(() => {
        history.replace(`/`);
      });
  }, []);

  if (!edition) return null;

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}>
        <img
          src={logo}
          style={{ animation: `spin 2s ease-in-out` }}
          className={classes.logo}
          alt=""
        />
      </div>
      <Typography
        color="textPrimary"
        className={classes.bodyText}
        align="center"
      >
        {t("edition:title")}
      </Typography>
      {edition.donor && (
        <Typography
          color="textPrimary"
          className={classes.title}
          align="center"
        >
          {edition.donor}
        </Typography>
      )}
      <Typography color="textPrimary" className={classes.bodyText}>
        {t("edition:stepsTitle")}
        <ol>
          <li>{t("edition:step1")}</li>
          <li>{t("edition:step2")}</li>
          <li>{t("edition:step3")}</li>
        </ol>
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("edition:install")}
      </Typography>
      <div className={classes.downloadLinks}>
        <a
          href="https://apps.apple.com/ie/app/qr-mine/id1579315203"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://static.wixstatic.com/media/ac97ab_fdc227d578f742f8a3f94cb72dddf87e~mv2.png/v1/fill/w_389,h_130,al_c,lg_1,q_85,enc_auto/ac97ab_fdc227d578f742f8a3f94cb72dddf87e~mv2.png"
            height={48}
            alt="AppStore"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.qrmine"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://static.wixstatic.com/media/ac97ab_b1b129890c5f40849c3aca2e5f511db8~mv2.png/v1/fill/w_490,h_190,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ac97ab_b1b129890c5f40849c3aca2e5f511db8~mv2.png"
            height={70}
            alt="Google Play"
          />
        </a>
      </div>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.bodyText}
      >
        {t("edition:bestRegards")}
      </Typography>
      <Typography
        color="textPrimary"
        align="center"
        className={classes.learnMore}
      >
        {t("edition:learnMore")}
        <a
          href="https://www.qr-mine.de/"
          className={classes.learnMoreLink}
          target="_blank"
          rel="noreferrer"
        >
          www.qr-mine.de
        </a>
      </Typography>

      <Footer />
    </div>
  );
};

export default Edition;
